<template>
  <div class="app-info-messages text-grey-500 p-22 bg-blue-200 w-full mb-48">
    <div class="information text-16">
      <img src="/imgs/info.svg" alt="info" class="inline icon ml-5 w-[21px]" />
      <span class="text"> {{ text }}</span>
    </div>
  </div>
</template>
<script>
export default {
  name: 'AppInfoMessages',
  props: {
    text: {
      required: true,
      type: String,
    },
  },
}
</script>
